/* eslint-disable @typescript-eslint/camelcase */
import { Vue } from "vue-class-component";
import { unparse } from "papaparse";

export default class OutGoingMixin extends Vue {
  downloadCsvFailedStt(params: { fileName: string; listStt: Array<any> }) {
    const sttBlobData: Array<any> = params.listStt?.map((item: any) => {
      const resp =  item["stt_no"] ?? item["sttNo"];
      return [resp];
    });
    const csvContent = unparse({
      fields: ["STT Number"],
      data: sttBlobData
    });
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    if ((navigator as any)?.msSaveBlob) {
      (navigator as any)?.msSaveBlob(blob, params.fileName);
    } else {
      const link = document.createElement("a");
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", params.fileName);
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  }
}
