import { toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "text-red-lp-300 font-normal pl-1"
}
const _hoisted_2 = { class: "text-gray-lp-500 font-normal" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("div", {
    class: `text-${_ctx.fontSize}px xxl:text-20px ${_ctx.fontColor} font-${_ctx.bold}`
  }, [
    _createTextVNode(_toDisplayString(_ctx.label) + " ", 1),
    (_ctx.required)
      ? (_openBlock(), _createBlock("span", _hoisted_1, "*"))
      : _createCommentVNode("", true),
    _createVNode("span", _hoisted_2, _toDisplayString(_ctx.hint), 1)
  ], 2))
}