
import { CustomProcessController } from "@/app/ui/controllers/CustomProcessController";
import { DetailCustomProcess } from "@/domain/entities/CustomProcess";
import { Vue } from "vue-class-component";
import ManifestPdfV2 from "./manifest-pdf-v2";
import { MainAppController } from "@/app/ui/controllers/MainAppController";

export default class Print extends Vue {
  // get profile account
  get dataProfile() {
    return CustomProcessController.dataProfile;
  }

  get detailCustomProcess() {
    return CustomProcessController.detailCustomProcess;
  }

  // get account type
  get isInternal() {
    return CustomProcessController.isInternal;
  }

  get typeUser() {
    return CustomProcessController.typeUser;
  }

  async printManifest(id: number) {
    MainAppController.showLoading();

    // logo
    const element: any = document;
    const logo = element.getElementById("logo")?.src;

    const printManifest =
      this.$route.params.id ||
      (await CustomProcessController.getDetailCustomProcess(id));
    if (printManifest) {
      const printData: DetailCustomProcess = this.detailCustomProcess;

      ManifestPdfV2(printData, logo, this.typeUser);
    }
    MainAppController.closeLoading();
  }

  // role account user
  get roleAccount() {
    if (
      this.dataProfile.account_type ||
      this.dataProfile.account_type_detail?.type
    ) {
      if (
        this.dataProfile.isCustomerService ||
        this.dataProfile.isInternalAccount
      ) {
        return "internal";
      } else if (this.dataProfile.account_type.toLowerCase() === "partner") {
        return this.dataProfile.account_type_detail.type;
      }
      return this.dataProfile.account_type;
    }
    return "";
  }
}
