import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule
} from "vuex-module-decorators";
import store from "@/store";
import {
  TrackingSttData,
  TrackingSttDetailData
} from "@/domain/entities/TrackingStt";
import { container } from "tsyringe";
import { TrackingPresenter } from "../presenters/TrackingPresenter";
import { TrackingCargoData } from "@/domain/entities/TrackingCargo";
import { NGenController } from "./NGenController";
import { TrackingCargoHistoryNgenApiRequest } from "@/data/payload/api/NGenApiRequest";
import { AwbDetailData, CargoHistoryNgen } from "@/domain/entities/NGen";
import { BookingController } from "@/app/ui/controllers/BookingController";

export interface TrackingState {
  isLoading: boolean;
  isError: boolean;
  errorCause: string;
}

@Module({
  namespaced: true,
  dynamic: true,
  store,
  name: "tracking-stt-cargo"
})
class TrackingSttCargoStore extends VuexModule implements TrackingState {
  public isLoading = false;
  public isSecondLoading = false;
  public isError = false;
  public errorCause = "";
  public showSttDetail = false;
  public showSttDetailCargo = false;
  public cargoHistoryNgen: CargoHistoryNgen[] = [];
  public trackingSttData = new TrackingSttData(new TrackingSttDetailData(), []);
  public trackingSttSecondData = new TrackingSttData(
    new TrackingSttDetailData(),
    []
  );
  public trackingCargoData = new TrackingCargoData(
    0,
    "",
    "",
    "",
    0,
    0,
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    0,
    0,
    0,
    0,
    []
  );

  @Action
  async convertSLATracking(params: {
    estimationSlaFormat: string;
    productType: string;
  }) {
    if (!params.estimationSlaFormat || params.estimationSlaFormat === "-")
      return "-";
    const bkd = this.trackingSttData.sttListTracking.filter((key: any) => {
      return key.status === "BKD";
    })[0];
    const resp = await BookingController.getEstimationServiceLevelAgreement({
      estimationSlaFormat: params.estimationSlaFormat,
      startDate: bkd?.statusDate,
      productType: params.productType
    });
    return `${resp.minDate} - ${resp.maxDate}`;
  }
  @Action
  async trackStt(params: {
    sttNo: string;
    isGetSla: boolean;
    isSecondCall?: boolean;
    cache?: boolean;
  }) {
    if (params.isSecondCall) {
      this.setSecondLoading(true);
    } else {
      this.setLoading(true);
      this.setShowSttDetail(false);
    }
    const presenter = container.resolve(TrackingPresenter);
    return presenter
      .getTrackingStt(params.sttNo, params.cache || false)
      .then(async (res: TrackingSttData) => {
        if (params.isSecondCall) {
          this.setTrackingSttSecondData(res);
        } else {
          this.setTrackingSttData(res);
        }

        if (params.isGetSla) {
          res.sttDetail.estimations = await this.convertSLATracking({
            estimationSlaFormat: res.sttDetail.estimations,
            productType: res.sttDetail.productType
          });
        }
        this.setShowSttDetail(true);
        this.setError(false);
        this.setErrorCause("");
        return true;
      })
      .catch(err => {
        this.setShowSttDetail(false);
        this.setError(true);
        if (!err.response) {
          this.setErrorCause("internet");
          return false;
        }
        switch (err.response.data.code) {
          case 404:
            this.setErrorCause("notfoundstt");
            break;
          case 422:
            this.setErrorCause("unusedstt");
            break;
          default:
            this.setErrorCause("server");
            break;
        }
        return false;
      })
      .finally(() => {
        this.setLoading(false);
        this.setSecondLoading(false);
        this.setShowSttDetailCargo(false);
      });
  }

  @Action
  async trackingCargo(cargoNo: string) {
    this.setLoading(true);
    const presenter = container.resolve(TrackingPresenter);
    return presenter
      .getTrackingCargo(cargoNo)
      .then((res: TrackingCargoData) => {
        this.setLoading(false);
        this.setTrackingCargoData(res);
        this.setShowSttDetailCargo(true);
        this.setError(false);
        this.setErrorCause("");
        if (res.cargoType === "plane") {
          this.trackingCargoHistoryNgen(
            new TrackingCargoHistoryNgenApiRequest(cargoNo)
          );
        }
        return true;
      })
      .catch(err => {
        this.setShowSttDetailCargo(false);
        this.setError(true);
        this.setLoading(false);
        if (!err.response) {
          this.setErrorCause("internet");
          return false;
        }
        switch (err.response.data.code) {
          case 400:
            this.setErrorCause("notfound");
            break;
          default:
            this.setErrorCause("server");
            break;
        }
        return false;
      })
      .finally(() => this.setLoading(false));
  }

  @Action
  async trackingCargoHistoryNgen(payload: TrackingCargoHistoryNgenApiRequest) {
    this.setLoading(true);
    const response: AwbDetailData = await NGenController.trackingCargoHistoryNgen(
      { payload }
    );
    if (
      response &&
      response?.awbTracking.length &&
      !response.errorAwb &&
      !NGenController.isError
    ) {
      this.setError(false);
      this.setErrorCause("");
      this.setTrackingCargoHistoryNgen(response.awbTracking);
    } else {
      this.setError(NGenController.isError);
      this.setErrorCause(response ? "server" : "internet");
    }
    this.setLoading(false);
  }

  @Mutation
  setTrackingSttData(val: TrackingSttData) {
    this.trackingSttData = val;
  }

  @Mutation
  setTrackingSttSecondData(val: TrackingSttData) {
    this.trackingSttSecondData = val;
  }

  @Mutation
  setTrackingCargoData(val: TrackingCargoData) {
    this.trackingCargoData = val;
  }

  @Mutation
  setTrackingCargoHistoryNgen(val: CargoHistoryNgen[]) {
    this.cargoHistoryNgen = val;
  }

  @Action
  initTrackingStt() {
    this.setError(false);
    this.setErrorCause("");
    this.setTrackingSttData(
      new TrackingSttData(new TrackingSttDetailData(), [])
    );
    this.setShowSttDetail(false);
  }

  @Mutation
  setLoading(val: boolean) {
    this.isLoading = val;
  }

  @Mutation
  setSecondLoading(val: boolean) {
    this.isSecondLoading = val;
  }

  @Mutation
  setError(val: boolean) {
    this.isError = val;
  }

  @Mutation
  setErrorCause(val: string) {
    this.errorCause = val;
  }

  @Mutation
  setShowSttDetail(val: boolean) {
    this.showSttDetail = val;
  }

  @Mutation
  setShowSttDetailCargo(val: boolean) {
    this.showSttDetailCargo = val;
  }
}

export const TrackingController = getModule(TrackingSttCargoStore);
