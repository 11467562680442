
import { Vue, Options } from "vue-class-component";
import UpdateCustomProcessV2 from "./update-v2.vue";

@Options({
  components: {
    UpdateCustomProcessV2
  }
})
export default class UpdateCustomProcess extends Vue {}
