
import { Options, Vue } from "vue-class-component";
import Close from "@/app/ui/components/icons/modules/close.vue";
@Options({
  emits: ["update:length", "update:width", "update:height", "change", "onClickViewDisabledInput"],
  components: {
    Close
  },
  props: {
    length: {
      default: 10,
      type: Number,
      required: true
    },
    width: {
      default: 20,
      type: Number,
      required: true
    },
    height: {
      default: 30,
      type: Number,
      required: true
    },
    disabled: {
      default: false,
      type: Boolean
    },
    error: {
      default: false,
      type: Boolean
    },
    errorCaption: {
      default: "",
      type: String
    },
    withIcon: {
      default: true,
      type: Boolean
    },
  showViewDisabledInput: {
    default: false,
    type: Boolean
  },
  }
})
export default class InputDimension extends Vue {
  props: any = this.$props;
  setInput(e: any) {
    e.target.value = e.target.value
      .replace(/[^0-9.,]/g, "")
      .replace(/[.]+|[,]+/g, ",")
      .replace(/,(\d+),/g, ",$1")
      .replace(/(,.*?),(.*,)?/, "$1")
      .replace(/^,/, "");

    const value = Number(e.target.value.replace(/,/g, "."));
    if (e.target.name === "length") {
      this.$emit("update:length", value);
      this.$emit("change", {
        length: value,
        width: this.props.width,
        height: this.props.height
      });
    } else if (e.target.name === "width") {
      this.$emit("update:width", value);
      this.$emit("change", {
        length: this.props.length,
        width: value,
        height: this.props.height
      });
    } else {
      this.$emit("update:height", value);
      this.$emit("change", {
        length: this.props.length,
        width: this.props.width,
        height: value
      });
    }
  }

  get lengthValue() {
    return this.props.length.toString().replace(".", ",");
  }

  get widthValue() {
    return this.props.width.toString().replace(".", ",");
  }

  get heightValue() {
    return this.props.height.toString().replace(".", ",");
  }

  onClickInput() {
    this.$emit("onClickViewDisabledInput");
  }
}
