
import { Vue, Options } from "vue-class-component";
import { CustomProcessController } from "@/app/ui/controllers/CustomProcessController";
import EmptyState from "@/app/ui/components/empty-list/index.vue";
import checkRolePermission from "@/app/infrastructures/misc/common-library/CheckRolePermission";
import formatDateWithoutTime from "@/app/infrastructures/misc/common-library/FormatDateWithoutTime";
import { CUSTOM_PROCESS } from "@/app/infrastructures/misc/RolePermission";
import Print from "./modules/print.vue";
import router from "../../router";

@Options({
  components: {
    EmptyState,
    Print
  },
  computed: {
    getCurrentRoute() {
      return this.$route.meta.title;
    },
    isDetailAble() {
      return checkRolePermission(CUSTOM_PROCESS.DETAIL);
    }
  }
})
export default class CustomProcess extends Vue {
  beforeMount() {
    CustomProcessController.setFilter({
      startDate: "",
      endDate: "",
      status: ""
    });
  }
  mounted() {
    CustomProcessController.initCustomProcessList();
    this.getReason();
    this.refs = this.$refs;
  }

  refs: any = "";

  fetchCustomProcessList() {
    CustomProcessController.fetchCustomProcessList();
  }

  // loading
  get isLoading() {
    return CustomProcessController.isLoading;
  }

  // error cause
  get errorCause() {
    return CustomProcessController.errorCause;
  }

  // filter date
  minDate: any = null;
  maxDate: any = null;
  isStartDate = true;
  setDay(day: any) {
    if (this.isStartDate) {
      CustomProcessController.setStartDate(null);
      CustomProcessController.setEndDate(null);
      this.minDate = new Date(
        new Date(day.id).setDate(new Date(day.id).getDate() - 13)
      );
      this.maxDate = new Date(
        new Date(day.id).setDate(new Date(day.id).getDate() + 13)
      );
      this.isStartDate = false;
    } else {
      this.endDate && (this.isStartDate = true);
    }
  }

  get startDate() {
    return CustomProcessController.filter.startDate;
  }

  get endDate() {
    return CustomProcessController.filter.endDate;
  }

  setDateRange(value: Array<any>) {
    CustomProcessController.setStartDate(value[0]);
    CustomProcessController.setEndDate(value[1]);
    value[0] && value[1] && this.fetchCustomProcessList();
  }

  // filter status
  statusSelect = false;
  onOpenStatusSelect() {
    this.statusSelect = true;
  }
  onCloseStatusSelect() {
    this.statusSelect = false;
  }
  statusName = "";
  get status() {
    return CustomProcessController.filter.status;
  }
  onSelectStatus(name: any, value: any) {
    CustomProcessController.setStatus(value);
    this.statusName = name;
    this.fetchCustomProcessList();
  }

  getReason() {
    CustomProcessController.fetchCustomProcessReasonList();
  }
  get statusList() {
    return CustomProcessController.customProcessReasonListStatus;
  }

  // Table
  get columns() {
    return [
      {
        name: "No.",
        styleHead: "w-24 text-left whitespace-no-wrap",
        render: (item: any, index: number) => {
          return `<div class="overflow-ellipsis text-left">${index + 1}</div>`;
        }
      },
      {
        name: "Total STT",
        styleHead: "w-40 text-left whitespace-no-wrap",
        render: (item: any) => {
          return `<div class="overflow-ellipsis text-left">${item.totalStt}</div>`;
        }
      },
      {
        name: this.$t("Total Koli"),
        styleHead: "w-40 text-left whitespace-no-wrap",
        render: (item: any) => {
          return `<div class="overflow-ellipsis text-left">${item.totalPiece}</div>`;
        }
      },
      {
        name: this.$t("Status Terbaru"),
        styleHead: "w-52 text-left whitespace-no-wrap",
        render: (item: any) => {
          return `<div class="text-black-lp-300">${item.latestStatus}</div>`;
        }
      },
      {
        name: "Remarks",
        styleHead: "w-60 text-left whitespace-no-wrap",
        render: (item: any) => {
          return `<div class="text-black-lp-300">${item.remarks}</div>`;
        }
      },
      {
        name: this.$t("Tanggal Diubah"),
        styleHead: "w-56 text-left whitespace-no-wrap",
        render: (item: any) => {
          return `<div class="overflow-ellipsis text-left capitalize">${formatDateWithoutTime(
            item.updatedAt
          )}</div>
            <div class="capitalize text-gray-lp-500 text-12px xxl:text-14px">${
              item.updatedBy
            }</div>`;
        }
      },
      {
        name: "Action",
        key: "button_column",
        styleHead: "w-50 text-left",
        styleButton: (item: any) => {
          return {
            outline: true,
            title: "Print",
            textColor: "black-lp-300",
            color: "gray-lp-400",
            class: "text-center",
            small: false,
            clickFunction: () => {
              this.refs.print.printManifest(item.id);
            }
          };
        }
      }
    ];
  }

  get pagination() {
    return CustomProcessController.customProcessList.pagination;
  }

  get customProcessList() {
    return CustomProcessController.customProcessList.data;
  }

  goToCreate() {
    router.push("/custom-status/update");
  }

  get isFirstRequest() {
    return CustomProcessController.isFirstRequest;
  }

  goToDetail(item: any) {
    this.$router.push(`/custom-status/${item.id}`);
  }
}
