
import { Options, Vue } from "vue-class-component";

@Options({
  emits: ["update:modelValue", "change", "update:weight"],
  props: {
    weight: {
      default: 0,
      type: Number
    },
    placeholder: {
      default: "0",
      type: String
    },
    adornment: {
      default: "Kg",
      type: String
    },
    position: {
      default: "right", // left or right
      type: String
    },
    maxLength: {
      default: 255,
      type: Number
    },
    errorCaption: {
      default: "",
      type: String
    },
    caption: {
      default: "",
      type: String
    },
    isError: {
      default: false,
      type: Boolean
    }
  }
})
export default class InputAdornment extends Vue {
  props: any = this.$props;
  onChange(e: any) {
    e.target.value = e.target.value
      .replace(/[^0-9.,]/g, "")
      .replace(/[.]+|[,]+/g, ",")
      .replace(/,(\d+),/g, ",$1")
      .replace(/(,.*?),(.*,)?/, "$1")
      .replace(/^,/, "");

    const value = Number(e.target.value.replace(/,/g, "."));
    this.$emit("change", value);
    this.$emit("update:weight", value);
  }

  get weightValue() {
    return this.props.weight.toString().replace(".", ",");
  }
}
