
import { Options, Vue } from "vue-class-component";
import DetailLayout from "@/app/ui/layout/detail-layout.vue";
import Title from "@/app/ui/components/data-wrapper/title.vue";
import OverlayPanel from "primevue/overlaypanel";
import {
  convertDecimalWithComma,
  formatDate
} from "@/app/infrastructures/misc/Utils";
import PrintManifest from "../modules/print.vue";
import { CustomProcessController } from "@/app/ui/controllers/CustomProcessController";
import { AccountController } from "@/app/ui/controllers/AccountController";
import { STT } from "@/domain/entities/CustomProcess";

@Options({
  components: {
    DetailLayout,
    Title,
    OverlayPanel,
    PrintManifest
  },
  computed: {
    getTitle() {
      return `${this.id}`;
    }
  }
})
export default class Detail extends Vue {
  refs: any = "";
  mounted() {
    this.refs = this.$refs;
    this.getDetail();
  }

  get id(): number {
    return Number(this.$route.params.id);
  }

  // route navigation
  goBack() {
    const path =
      (this.$route.meta as any)["parent-path"] || this.$route.matched[0].path;
    this.$router.push(path);
  }

  getDetail() {
    CustomProcessController.getDetailCustomProcess(this.id);
  }

  get columns() {
    const columns = [
      {
        name: "No.",
        styleHead: "w-10 text-left whitespace-no-wrap",
        styleCustom: "align-top",
        render: (item: STT, index: number) => {
          return `<div class="text-black-lp-300">${index + 1}</div>`;
        }
      },
      {
        name: "No. STT",
        styleHead: "w-48 text-left whitespace-no-wrap",
        styleCustom: "align-top",
        render: (item: STT) => {
          return `<div class="text-black-lp-300">${item.sttNo}</div>`;
        }
      },
      {
        name: this.$t("No. STT Pengganti"),
        styleHead: "w-56 text-left whitespace-no-wrap",
        styleCustom: "align-top",
        render: (item: STT) => {
          return `<div class="text-black-lp-300">${item.sttReplacementNo ||
            item.remarks ||
            "-"}</div>`;
        }
      },
      {
        name: "Remarks",
        styleHead: "w-44 text-left whitespace-no-wrap",
        styleCustom: "align-top",
        render: (item: STT) => {
          return `<div class="text-black-lp-300">${item.remarks || "-"}</div>`;
        }
      },
      {
        name: this.$t("Berat Kotor"),
        styleHead: "w-36 text-left whitespace-no-wrap",
        styleCustom: "align-top",
        render: (item: STT) => {
          return `<div class="text-black-lp-300">${convertDecimalWithComma(
            item.totalGrossWeight,
            2
          )} Kg</div>`;
        }
      },
      {
        name: this.$t("Berat Dimensi"),
        styleHead: "w-36 text-left whitespace-no-wrap",
        styleCustom: "align-top",
        render: (item: STT) => {
          return `<div class="text-black-lp-300">${convertDecimalWithComma(
            item.totalVolumeWeight,
            2
          )} Kg</div>`;
        }
      },
      {
        name: this.$t("Produk"),
        styleHead: "w-36 text-left whitespace-no-wrap",
        styleCustom: "align-top",
        render: (item: STT) => {
          return `<div class="text-black-lp-300 flex">
                  <div class="rounded px-2 py-0 bg-gray-lp-400">
                      ${item.productType}
                  </div>
                </div>`;
        }
      },
      {
        name: this.$t("Komoditas"),
        styleHead: "w-64 text-left whitespace-no-wrap",
        styleCustom: "align-top",
        render: (item: STT) => {
          return `<div class="text-black-lp-300">${item.commodityName}</div>`;
        }
      },
      {
        name: this.$t("Total Koli"),
        styleHead: "w-32 text-left whitespace-no-wrap",
        styleCustom: "align-top",
        render: (item: STT) => {
          return `<div class="text-black-lp-300">${item.totalPieces}</div>`;
        }
      },
      {
        name: this.$t("Kota Asal"),
        styleHead: "w-40 text-left whitespace-no-wrap",
        styleCustom: "align-top",
        render: (item: STT) => {
          return `<div class="text-black-lp-300">${item.originCityName}</div>`;
        }
      },
      {
        name: this.$t("Kota Tujuan"),
        styleHead: "w-40 text-left whitespace-no-wrap",
        styleCustom: "align-top",
        render: (item: STT) => {
          return `<div class="text-black-lp-300">${item.destinationCityName}</div>`;
        }
      },
      {
        name: "Origin",
        styleHead: "w-24 text-left whitespace-no-wrap",
        styleCustom: "align-top",
        render: (item: STT) => {
          return `<div class="text-black-lp-300 flex">
                  <div class="rounded px-2 py-0 bg-gray-lp-400">
                      ${item.originCityId}
                  </div>
                </div>`;
        }
      },
      {
        name: "Destination",
        styleHead: "w-28 text-left whitespace-no-wrap",
        styleCustom: "align-top",
        render: (item: STT) => {
          return `<div class="text-black-lp-300 flex">
                  <div class="rounded px-2 py-0 bg-gray-lp-400">
                      ${item.destinationCityId}
                  </div>
                </div>`;
        }
      }
    ];
    columns.splice(
      this.detailData.customProcessLatestStatus.match(/RTS|^REROUTE/gi) &&
        this.dataProfile.isRtsFeatureAvailable
        ? 3
        : 2,
      1
    );
    return columns;
  }

  get detailData() {
    return CustomProcessController.detailCustomProcess;
  }

  get convertCreatedAt() {
    return formatDate(this.detailData.customProcessCreatedAt);
  }
  // get profile account
  get dataProfile() {
    return AccountController.accountData;
  }

  // get account type
  get isInternalOrCs() {
    return (
      CustomProcessController.isInternal ||
      CustomProcessController.isCustomerService
    );
  }

  get typeUser() {
    return CustomProcessController.typeUser;
  }

  get partnerName() {
    return this.isInternalOrCs
      ? this.detailData.customProcessCreatedBy
      : this.detailData.customProcessPartnerName;
  }

  get isLoading() {
    return CustomProcessController.isLoadingDetail;
  }
  get isError() {
    return CustomProcessController.isErrorDetail;
  }
  get errorCause() {
    return CustomProcessController.errorCause;
  }

  // handle print
  printManifest() {
    this.refs.printManifest.printManifest(this.id);
  }
}
