import { toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "rounded-l bg-gray-lp-700 text-black-lp-300 text-center border-l border-gray-lp-400 outline-none items-center py-2 xxl:py-3 text-12px xxl:text-14px px-2"
}
const _hoisted_2 = {
  key: 1,
  class: "rounded-r bg-gray-lp-700 text-black-lp-300 text-center border-l border-gray-lp-400 outline-none items-center py-2 xxl:py-3 text-12px xxl:text-14px px-2"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode("div", {
      class: 
      `${
        _ctx.isError ? 'border-red-lp-100' : 'border-gray-lp-400'
      } flex flex-row border rounded items-center w-full`
    
    }, [
      (_ctx.position === 'left')
        ? (_openBlock(), _createBlock("div", _hoisted_1, _toDisplayString(_ctx.adornment), 1))
        : _createCommentVNode("", true),
      _createVNode("input", {
        type: "text",
        onInput: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onChange && _ctx.onChange(...args))),
        value: _ctx.weightValue,
        placeholder: _ctx.placeholder,
        class: 
        `${
          _ctx.position === 'right' ? 'rounded-l' : 'rounded-r'
        } py-2 xxl:py-3 px-3 focus:outline-none text-black-lp-300 w-full`
      ,
        maxlength: _ctx.maxLength
      }, null, 42, ["value", "placeholder", "maxlength"]),
      (_ctx.position === 'right')
        ? (_openBlock(), _createBlock("div", _hoisted_2, _toDisplayString(_ctx.adornment), 1))
        : _createCommentVNode("", true)
    ], 2),
    _withDirectives(_createVNode("div", {
      class: 
      `text-12px ${_ctx.errorCaption ? 'text-red-lp-1000' : 'text-gray-lp-500'} mt-2`
    
    }, _toDisplayString(_ctx.errorCaption || _ctx.caption), 3), [
      [_vShow, _ctx.caption || _ctx.isError]
    ])
  ], 64))
}