import { toDisplayString as _toDisplayString, createVNode as _createVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "bg-white flex flex-col text-12px xxl:text-14px overflow-hidden h-screen relative w-full" }
const _hoisted_2 = { class: "flex z-20 items-center justify-between mt-6 mb-2" }
const _hoisted_3 = { class: "flex" }
const _hoisted_4 = { class: "font-semibold text-20px mr-4 whitespace-no-wrap text-black-lp-200 capitalize" }
const _hoisted_5 = { class: "hidden" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Select = _resolveComponent("Select")!
  const _component_time_range_picker = _resolveComponent("time-range-picker")!
  const _component_lp_button = _resolveComponent("lp-button")!
  const _component_EmptyState = _resolveComponent("EmptyState")!
  const _component_TableV2 = _resolveComponent("TableV2")!
  const _component_Print = _resolveComponent("Print")!

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode("div", _hoisted_1, [
      _createVNode("div", _hoisted_2, [
        _createVNode("div", _hoisted_3, [
          _createVNode("div", _hoisted_4, _toDisplayString(_ctx.getCurrentRoute), 1),
          (_ctx.statusList.length)
            ? (_openBlock(), _createBlock(_component_Select, {
                key: 0,
                dataSelect: _ctx.statusList,
                onOpenSelect: _ctx.onOpenStatusSelect,
                onCloseSelect: _ctx.onCloseStatusSelect,
                onChooseSelect: _ctx.onSelectStatus,
                value: _ctx.statusName,
                isOpenMenu: _ctx.statusSelect,
                class: 'w-48'
              }, null, 8, ["dataSelect", "onOpenSelect", "onCloseSelect", "onChooseSelect", "value", "isOpenMenu"]))
            : _createCommentVNode("", true),
          _createVNode(_component_time_range_picker, {
            startDate: _ctx.startDate,
            endDate: _ctx.endDate,
            onSelect: _ctx.setDateRange,
            default: _ctx.$t('7 hari terakhir'),
            showOptions: 
            `${_ctx.$t('custom')}, ${_ctx.$t('hari ini')}, ${_ctx.$t('7 hari terakhir')}, ${_ctx.$t(
              '14 hari terakhir'
            )}`
          ,
            minDate: _ctx.minDate,
            maxDate: _ctx.maxDate,
            onDayClick: _ctx.setDay
          }, null, 8, ["startDate", "endDate", "onSelect", "default", "showOptions", "minDate", "maxDate", "onDayClick"])
        ]),
        _createVNode(_component_lp_button, {
          onClick: _ctx.goToCreate,
          title: "Update Status",
          textColor: "white"
        }, null, 8, ["onClick"])
      ]),
      (_ctx.isFirstRequest && !_ctx.isLoading && _ctx.customProcessList.length === 0)
        ? (_openBlock(), _createBlock(_component_EmptyState, {
            key: 0,
            data: "Custom Process"
          }))
        : (_openBlock(), _createBlock(_component_TableV2, {
            key: 1,
            columns: _ctx.columns,
            data: _ctx.customProcessList,
            loading: _ctx.isLoading,
            pagination: _ctx.pagination,
            "onUpdate:pagination": _cache[1] || (_cache[1] = ($event: any) => (_ctx.pagination = $event)),
            "border-top": false,
            onRequest: _ctx.fetchCustomProcessList,
            errorCause: _ctx.errorCause,
            isDetailAble: _ctx.isDetailAble,
            onClick: _ctx.goToDetail
          }, null, 8, ["columns", "data", "loading", "pagination", "onRequest", "errorCause", "isDetailAble", "onClick"]))
    ]),
    _createVNode("div", _hoisted_5, [
      _createVNode(_component_Print, { ref: "print" }, null, 512)
    ])
  ], 64))
}